<template>
  <LayoutEmpty>
    <v-container>
      <v-row>
        <v-col class="d-flex justify-center mt-2">
          <LogoFarmConnect :width="500" />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex justify-center">
          <v-icon size="40">mdi-emoticon-sad-outline</v-icon>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-h6 text-center">
          {{ $t("common.somethingWrong") }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-subtitle-1 red--text text-center">
          <slot>
            {{ message }}
          </slot>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="text-body-2 text-center mt-6 py-0 grey--text text--darken-1"
        >
          <span v-if="error">{{ error }}</span>
          <p>{{ $t("error.writeUs") }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex justify-center my-4">
          <base-button @click="onClick" :loading="refreshing">
            <v-icon class="mr-2">mdi-refresh</v-icon>
            {{ $t("common.refresh") }}
          </base-button>
        </v-col>
      </v-row>
    </v-container>
  </LayoutEmpty>
</template>

<script>
import LogoFarmConnect from "@components/base/LogoFarmConnect.vue";

export default {
  name: "ErrorPage",
  components: {
    LogoFarmConnect,
  },
  page: {
    title: "Error",
  },
  props: {
    error: {
      required: false,
    },
    message: {
      required: false,
      default: "unknown error", // this.$t('error.unknown')
    },
  },
  data() {
    return {
      refreshing: false,
    };
  },
  methods: {
    onClick() {
      this.refreshing = true;
      window.location.reload();
    },
  },
};
</script>

<style scoped></style>
